import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const EditorialPolicy = () => {
  return (
    <Wrapper style={{ padding: "0" }}>
      <div id="wrapper" style={{ padding: "0" }}>
        <div className="content" style={{ marginTop: "70px" }}>
          <div className="breadcrumbs-header fl-wrap">
            <div className="container">
              <div className="breadcrumbs-header_url">
                <Link to="/">Home</Link>
                {/* <span>Editorial Policy</span> */}
              </div>
            </div>
            <div className="pwh_bg"></div>
          </div>

          <section>
            <div className="container">
              <div className="row">
                <div className="mb-4">
                  <div className="section-title sect_dec">
                    <h2>1. Editorial Board</h2>
                  </div>
                  <div className="mb-4">
                    <h4 className="textt mb-2">Editor-in-Chief</h4>
                    <h4 className="teext mb-2">Dr. Amna Hassan,</h4>
                    <h4 className="texxt mb-2">
                      Post-Doctoral Fellow, Università Mediterranea di Reggio
                      Calabria, Italy,
                    </h4>
                    <h4 className="texxt mb-2">
                      Visiting Professor, Krakow University of Economics,
                      Poland.
                    </h4>
                    <h4 className="texxt mb-2">
                      Email:{" "}
                      <Link
                        className="linnk"
                        to="mailto:editor.sajlp@gmail.com"
                      >
                        editor.sajlp@gmail.com.{" "}
                      </Link>
                    </h4>
                  </div>

                  <div className="mb-4">
                    <h4 className="textt mb-2">Managing Editors</h4>
                    <h4 className="texxt mb-2">
                      Dr. Sams Uddin Ahmed, Professor, School of Law, BRAC
                      University, Bangladesh.
                    </h4>
                    <h4 className="texxt mb-2">
                      Dr. Kis Kelemen Bence, Senior Lecturer, Department of
                      International and European Law, University of Pecs,
                      Hungary.
                    </h4>
                    <h4 className="texxt mb-2">
                      Dr. Tugce Kilic, Policy and Advocacy Assistant,
                      Transparency International, Berlin, Germany.
                    </h4>
                    <h4 className="texxt mb-2">
                      Dr. Sabrina Hasan, Assistant Professor, Faculty of Law,
                      East China University of Political Science and Law, China.
                    </h4>
                    <h4 className="texxt mb-2">
                      Dr. Muhammed Atiqur Rahman, Assistant Professor, School of
                      Law, BRAC University, Bangladesh.
                    </h4>
                    <h4 className="texxt mb-2">
                      Dr. Kenneth Kaunda Kodiyo, Assistant Professor, Catholic University
                      of Eastern Africa, Faculty of Law, Kenya.
                    </h4>
                    <h4 className="texxt mb-2">
                      Dr. Saru Arifin, Lecturer, Semarang State University,
                      Semarang, Indonesia.
                    </h4>
                    <h4 className="texxt mb-2">
                      Dr. Mostafa Hosain, Assistant Professor, School of Law,
                      BRAC University, Bangladesh.
                    </h4>
                    <h4 className="texxt mb-2">
                      Dr. Phet Sengpunya, Founder & Managing Partner, Grandeur
                      Law & Partner, Vientiane, Lao PDR.
                    </h4>
                    <h4 className="texxt mb-2">
                      Mr. Ragib Chowdhury, LLM Candidate, University of British
                      Columbia, Canada.
                    </h4>
                  </div>

                  <div className="mb-4">
                    <h4 className="textt mb-2">Management & Policy Chief</h4>
                    <h4 className="texxt mb-2">
                      <Link
                        className="linnk"
                        to="https://www.linkedin.com/in/upal-aditya-oikya"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Dr. Upal Aditya Oikya
                      </Link>
                      , Assistant Professor, School of Law, BRAC University,
                      Bangladesh. Email:
                      <Link
                        className="linnk"
                        to="mailto:aditya.oikya@gmail.com"
                      >
                        {" "}
                        aditya.oikya@gmail.com
                      </Link>
                      .
                    </h4>
                  </div>
                </div>

                <div className="mb-4">
                  <div className="section-title sect_dec">
                    <h2>2. Submission & Review Process</h2>
                  </div>
                  <div className="about-wrap">
                    <p className="ppp_text">
                      The journal considers all submissions based on topic,
                      academic content, and style. The promising articles
                      undergo single-blind peer review by experts in their
                      respective fields, where the reviewers are aware of the
                      identity of the authors, but the authors are unaware of
                      the identity of the reviewers. Reviewers assess the
                      article's scope, accuracy, structure, language,
                      references, academic contribution, and ability to
                      distinguish facts from opinions. The editorial board makes
                      the final decision on publication. Therefore, authors must
                      make requested revisions before publication. The journal
                      accepts submissions previously presented at conferences or
                      loaded on pre-print servers. For the editors and
                      reviewers, their primary responsibilities include
                      maintaining a steady flow of high-quality manuscripts,
                      staying abreast of emerging trends in the field, upholding
                      ethical standards, selecting and engaging the Editorial
                      Board, contributing to strategic development, staying
                      informed about global developments, identifying potential
                      revenue sources, and promoting the journal to different
                      networks. The editors must comply with the COPE's guide to
                      ethical editing
                      <Link
                        className="linnk"
                        to="https://publicationethics.org/files/A_Short_Guide_to_Ethical_Editing.pdf"
                        target="_blank"
                      >
                        {" "}
                        (https://publicationethics.org/files/A_Short_Guide_to_Ethical_Editing.pdf)
                      </Link>
                      .
                    </p>
                  </div>
                </div>

                <div className="text-start mb-2">
                  <u className="textt">Flow Chart of the Editorial Process:</u>
                </div>
                <div className="ulll_content mb-4">
                  <p className="ppp_text">
                    <span className="fw-bold">1. Pre-check:</span> Immediately
                    after submission, the journal’s Managing Editor will perform
                    the technical pre-check to assess the overall suitability of
                    the manuscript to the journal/section/Special Issue,
                    manuscript adherence to high-quality research and ethical
                    standards, and standards of rigor to qualify for further
                    review. The Editor-in-Chief will perform a pre-check. Upon
                    successful pre-check, the paper will be sent for
                    single-blinded peer review.
                  </p>
                  <p className="ppp_text">
                    <span className="fw-bold">2. Peer Review:</span> The
                    promising articles undergo single-blind peer review by
                    experts in their respective fields, where the reviewers are
                    aware of the identity of the authors, but the authors are
                    unaware of the identity of the reviewers.
                  </p>
                  <p className="ppp_text">
                    <span className="fw-bold">
                      3. Editorial Decision and Revision:
                    </span>
                    &nbsp; The decision of the peer review will be one of the
                    following:
                  </p>
                  <p className="ppp_textt">
                    <strong className="fw-bold">i. </strong> &nbsp;Accept with
                    Minor Revisions: The paper is in principle accepted after
                    revision based on the reviewer’s comments. Authors are given
                    five days for minor revisions.
                  </p>
                  <p className="ppp_textt">
                    <strong className="fw-bold">ii . </strong> &nbsp;Reconsider
                    with Major Revisions: The acceptance of the manuscript will
                    depend on the revisions. The author is required to provide a
                    detailed response to the reviewer's comments or provide a
                    rebuttal if some comments cannot be addressed, within 20
                    days after getting the feedback.
                  </p>
                  <p className="ppp_textt">
                    <strong className="fw-bold">iii. </strong> &nbsp;Reject and
                    Encourage Resubmission: In case of a vague
                    conclusion/analysis, the paper will be rejected and the
                    authors will be encouraged to re-submit the paper once a
                    precise conclusion through scientific analysis has been
                    conducted.
                  </p>
                  <p className="ppp_textt">
                    <strong className="fw-bold">iv. </strong> &nbsp;Reject: The
                    article has serious flaws, and/or makes no original
                    significant contribution. No offer of resubmission to the
                    journal is provided.
                  </p>
                  <p className="ppp_text">
                    <span className="fw-bold">4. Appeals:</span> Authors may
                    appeal a rejection by sending an e-mail to the Editorial
                    Office of the journal. The appeal must provide a detailed
                    justification, including point-by-point responses to the
                    reviewers’ comments. The Editor-in-Chief will decide on this
                    issue, upon getting advice from the Editorial Board members.
                    A reject decision at this stage is final and cannot be
                    reversed.
                  </p>
                  <p className="ppp_text">
                    <span className="fw-bold">5. Article Processing Fee:</span>{" "}
                    Our efficient single-blind peer review system ensures a
                    quick turnaround for your manuscript. If your work receives
                    positive feedback, SAJLP will notify the author for a
                    nominal publication fee to secure its publication. This fee
                    is payable within{" "}
                    <span className="fw-bold">ten (10) business days</span>.
                    Upon receiving payment, the author will receive an
                    acceptance certificate directly from the Editor-in-Chief
                    <Link className="linnk" to="mailto:editor.sajlp@gmail.com">
                      (editor.sajlp@gmail.com)
                    </Link>
                    . Please note that we prioritize transparency and avoid
                    third-party involvement. SAJLP is the sole service provider
                    for publication fees. Facing financial constraints shouldn't
                    hinder your research journey. Authors facing financial
                    hardship can apply for a waiver. Simply contact the
                    Editor-in-Chief soon after receiving the payment
                    notification. To ensure a smooth publication process, please
                    submit your payment or waiver request within five (05)
                    business days. Failure to do so will result in manuscript
                    rejection due to non-payment.
                  </p>
                  <p className="ppp_text">
                    <span className="fw-bold">
                      6. Production and Publication:
                    </span>{" "}
                    Once accepted, the manuscript will undergo professional
                    copy-editing, English editing, proofreading by the authors,
                    final corrections, pagination, and, publication on the
                    journal website.
                  </p>
                </div>
                {/* ====== order list ======*/}
                <div className="mb-4">
                  <div className="section-title sect_dec">
                    <h2>3. Authorship & Ethics</h2>
                  </div>
                  <div>
                    <ul
                      style={{ listStyleType: "disc", paddingLeft: "20px" }}
                      className="ppp_text"
                    >
                      <li className="mb-2">
                        Editorial board members can submit papers but will be
                        removed from editorial tasks for that paper to ensure
                        fairness.
                      </li>
                      <li className="mb-2">
                        Reviewers are expected to provide feedback on content,
                        structure, figures/tables, formatting, and language.
                      </li>
                      <li className="mb-2">
                        The journal encourages open data, structured methods,
                        and open code for reproducibility.
                      </li>
                      <li className="mb-2">
                        Authors are encouraged to register with ORCID for proper
                        attribution.
                      </li>
                      <li className="mb-2">
                        Authorship follows ICMJE guidelines, and all authors
                        must meet those criteria.
                      </li>
                      <li className="mb-2">
                        Conflict of Interest(s), funding source(s), and ethical
                        research details must be declared.
                      </li>
                      <li className="mb-2">
                        The journal takes issues of copyright infringement,
                        plagiarism, or other breaches of best practice in
                        publication very seriously.
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="mb-4">
                  <div className="section-title sect_dec">
                    <h2>4. Post-Publication Issues</h2>
                  </div>
                  <div className="about-wrap">
                    <p className="ppp_text">
                      Corrections and retractions are handled according to COPE
                      guidelines. Authors proofread articles before publication
                      to minimize content errors. Post-publication changes are
                      generally not permitted unless exceptional circumstances
                      exist.
                    </p>
                  </div>
                </div>

                <div className="mb-4">
                  <div className="section-title sect_dec">
                    <h2>5. Appeals, Complain & Misconducts</h2>
                  </div>
                  <div className="about-wrap">
                    <p className="ppp_text">
                      All appeals, complain, or allegations of misconduct are
                      taken seriously, regardless of source or publication
                      stage. COPE guidelines are followed to address any issues.
                      For submitting appeals, complain, or misconduct concerns,
                      please contact the Management & Policy Chief at
                      <Link
                        className="linnk"
                        to="mailto:aditya.oikya@gmail.com"
                      >
                        {" "}
                        aditya.oikya@gmail.com
                      </Link>
                      , with a cc to
                      <Link
                        className="linnk"
                        to="mailto:editor.sajlp@gmail.com"
                      >
                        {" "}
                        editor.sajlp@gmail.com
                      </Link>
                      .
                    </p>
                  </div>
                </div>

                <div className="mb-4">
                  <div className="section-title sect_dec">
                    <h2>6. Expected Behavior</h2>
                  </div>
                  <div className="about-wrap">
                    <p className="ppp_text">
                      Abusive behavior towards staff, editors, authors, or
                      reviewers will not be tolerated and will result in
                      withdrawn contributions and potential exclusion from
                      future engagement.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="sec-dec"></div>
          </section>
        </div>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.section`
  .section-title {
    margin-bottom: 20px !important;
  }
  .ppp_text {
    text-align: justify !important;
  }
  .linnk {
    color: #0563c1;
  }
  .textt {
    font-size: 17px !important;
    font-family: serif !important;
    color: #000 !important;
    font-weight: 700 !important;
    text-align: start !important;
  }
  .texxt {
    font-size: 17px !important;
    font-family: serif;
    /* font-style: italic; */
    color: #000 !important;
    /* font-weight: 400 !important; */
    text-align: start !important;
    margin-left: 10px !important;
  }
  .teext {
    font-size: 17px !important;
    font-family: serif !important;
    color: #0563c1 !important;
    font-weight: 500 !important;
    text-align: start !important;
    margin-left: 10px !important;
  }
  .ppp_text {
    font-size: 14px !important;
    color: #000 !important;
  }
  .ulll_content {
    margin-left: 10px !important;
  }
  .ppp_textt {
    font-size: 14px !important;
    color: #000 !important;
    margin-left: 30px !important;
  }
`;

export default EditorialPolicy;
