const blogData = [
  {
    id: 1,
    title: "E-waste Management: Bangladesh Perspective",
    category: "Analysis",
    // image: "/images/all/1.jpg",
    image: "/images/all-issues-image/image.jpg",
    images: [
      "/images/all/1.jpg",
      "/images/all/20.jpg",
      "/images/all/13.jpg",
      "/images/all/15.jpg",
    ],
    author: "N. Nabila Hoque, Barrister-at-Law",
    authorImage: "/images/avatar/users.jpg",
    date: "April 2021",
    isMostview: false,
    // description:
    //   "Struggling to sell one multi-million dollar home quite on currently the market easily dollar home quite.",
    copyright: "© Image Copyrights Title",
    paragraph: [
      {
        id: 1,
        text: "Abstract",
        description: `<p class="" style="text-align: justify;">Electronic Waste (E-waste) is evolving form electronic goods containing both hazardous sub-
                      stance along with precious metals. The hazardous nature of e-waste is damaging public health

                      and environment where as the precious metals obtained in the process is attracting economic

                      benefits. The use of e-goods have increased higher standard of people’s life and brought pros-
                      perity for the nation but at the same time created e-waste has directed problems at different

                      levels. Developed countries produce huge amount of e-waste which they mostly export to the

                      developing countries for their economic benefits. Complementing the vision of a digital Bang-
                      ladesh the usage of electrical and electronic goods/technology has been rapidly accelerating.

                      This is leading to creation of massive amount of e-waste that are being dumped in open land-
                      fills and water bodies instead of safe disposal. The purpose of this paper is to identify the

                      challenges and law in the matters involving overall e-waste management of developing coun-
                      tries like Bangladesh. Meticulous desk based research has been done to accomplish the same.

                      The Basel Convention, 1989 prohibits all trans-boundary movement of hazardous waste,

                      Bangladesh is a signatory of the said convention. Bangladesh Import Policy Order and Haz-
                      ardous Waste and Ship Breaking Waste Management Rules, prohibits importing any hazard-
                      ous waste including e-waste nevertheless, in reality a large quantity of e-waste is imported and

                      exists mostly in the informal in the country. However, there are no proper e-waste treatment
                      facilitiesin the formal sector of the country, though electronic goods recycling and dismantling
                      is a growing business in Bangladesh. Majority of the said recycling, for extraction of precious
                      metals, is being carried out in the informal sector. The uncontrolled recycling process is very
                      injurious to the environment and the people involved. The import and recycling of e-waste is
                      undeniably important for the economy, even though with its precarious traits. The recycling

                      should be done in the well regulated formal sector following proper safety measures. The e-
                      waste, possess threat to both living organisms and the environment thus problem needs to be

                      handled delicately with due diligence.</p>`,
      },
    ],
    pdfPath:
      "https://drive.google.com/file/d/1QNOJ6Xarr_MTAk6YRDus-3oxrdqK6fSe/view?usp=sharing",
  },
  {
    id: 2,
    title:
      "Issues of International Crimes Tribunal Bangladesh: Legitimacy, Normativity and Legality",
    category: "Analysis",
    image: "/images/all-issues-image/image.jpg",
    images: [
      "/images/all/1.jpg",
      "/images/all/20.jpg",
      "/images/all/13.jpg",
      "/images/all/15.jpg",
    ],
    author: "Mr. Muhtasim Fahmid and Ms. Bushra Haque, University of Dhaka",
    authorImage: "/images/avatar/users.jpg",
    date: "April 2021",
    isMostview: false,
    // description:
    //   "Struggling to sell one multi-million dollar home quite on currently the market easily dollar home quite.",
    copyright: "© Image Copyrights Title",
    paragraph: [
      {
        id: 1,
        text: "Abstract",
        description: `<p class="" style="text-align: justify;">
          The liberation war of Bangladesh was a bloody and visceral ordeal, claiming more than three
          million lives and laying waste to the infrastructure of the country in an abysmal level. The
          oppressive nature of the West Pakistan government, and their open dismay about the Bengali
          population led to the massive popular uprising in what would become Bangladesh. Millions of
          lives were lost in the war that followed, along with some of the most notorious breaches of
          international law and the Geneva Convention. After liberation, the government of Bangladesh
          made attempts to bring the perpetrators of war crimes to justice, but the attempts were not
          fulfilled. The process was restarted in 2009, garnering both positive and negative reviews from
          different stakeholders. There were questions about the standard of the trials and the conformity
          with international norms and legislations. The main goal of this essay shall be to prove the
          legitimacy – both legally and politically, of the International Crimes Tribunal of Bangladesh,
          and to debunk the accusations regarding the Tribunal, especially its accused violation of
          International Standards, and the complaint about being politically biased.
        </p>`,
      },
    ],
    pdfPath:
      "https://drive.google.com/file/d/1rgQP7ZIFfY_Ecsv0VjXRBwvXOGHMtELX/view?usp=sharing",
  },
  {
    id: 3,
    title:
      "A Legal Study on Muslim Women’s Guardianship and Custody Rights in Bangladesh",
    category: "Analysis",
    image: "/images/all-issues-image/image.jpg",
    images: [
      "/images/all/1.jpg",
      "/images/all/20.jpg",
      "/images/all/13.jpg",
      "/images/all/15.jpg",
    ],
    author: "Ms. Tanima Rahman, Advocate, Supreme Court of Bangladesh",
    authorImage: "/images/avatar/users.jpg",
    date: "April 2021",
    isMostview: false,
    // description:
    //   "Struggling to sell one multi-million dollar home quite on currently the market easily dollar home quite.",
    copyright: "© Image Copyrights Title",
    paragraph: [
      {
        id: 1,
        text: "Abstract",
        description: `<p class="" style="text-align: justify;">
          Owing to the disproportionate traditional, social and religious practices, the position of women in
          Bangladesh remained inferior in society. Although the Constitution of Bangladesh guarantees both
          men and women to provide equal rights in all spheres of state and public life, in reality this equality
          in the law is not applicable to women because the separate personal laws, which govern family
          lives of citizens of Bangladesh, reflect gender inequality. Women are not permitted to have equal
          status and rights in areas of property, marriage, divorce, guardianship and child custody. Mothers
          following to separation are not entitled to claim child’s custody after a specific period of time, let
          alone guardianship. The study attempts to examine child custody and guardianship laws in
          Bangladesh after couple’s depart due to separation, dissolution of marriage or divorce;
          furthermore, the paper critically argues for the vulnerable position of women in the society and
          explains how women in Bangladesh are oppressed by religious fanaticism. In this essay child
          custody and guardianship laws in Bangladesh after couple’s depart, conflict between
          guardianship law and CEDAW (the Convention for the Elimination of All Forms of Discrimination
          against Women) provisions, and court’s attempts towards establishing welfare doctrine above
          personal law will be discussed.
        </p>`,
      },
    ],
    pdfPath:
      "https://drive.google.com/file/d/12bO8ConfROg5ZLWSGv4i5tPPN8NYNIR-/view?usp=sharing",
  },
  {
    id: 4,
    title: "Ship Breaking in Bangladesh and Its Impact",
    category: "Analysis",
    image: "/images/all-issues-image/image.jpg",
    images: [
      "/images/all/1.jpg",
      "/images/all/20.jpg",
      "/images/all/13.jpg",
      "/images/all/15.jpg",
    ],
    author: "Tanima Rahman, Lecturer, School of Law, East West University.",
    authorImage: "/images/avatar/users.jpg",
    date: "April 2021",
    isMostview: false,
    // description:
    //   "Struggling to sell one multi-million dollar home quite on currently the market easily dollar home quite.",
    copyright: "© Image Copyrights Title",
    paragraph: [
      {
        id: 1,
        text: "Abstract",
        description: `<p class="" style="text-align: justify;">
          Ship breaking is a process of dismantling old ships for scrapping. While in order to save
          the environment from toxic waste most countries are following strict environmental rules
          and regulation, Bangladesh on the other hand is still pursuing environmental laws that
          are flexible in nature and less rigorous towards maintaining toxic substances and waste
          disposal. Bangladesh being a poor and over populated country, labour cost is reasonably
          low here. For working 8-9 hours a day, a labour at the shipyard gets only 85-180 taka
          (1-2 USD) as salary. They get no overtime, sick or annual leave. Owing to such weaker
          environmental laws and cheap labour cost, Bangladesh has become a lucrative place for
          the development of ship recycling. Currently, 52 percent ships from all over the world are
          send to Bangladesh for scrapping. However, the hazardous materials from ship exteriors
          are causing serious pollution to the beach and surrounding marine environment. Workers
          at the shipyard are working in an inhuman condition without safety equipment. Fatal
          injuries and accidental deaths of the workers has become daily incidents. Thus, ship
          braking in Bangladesh has become a subject to major human rights violation.
          Nevertheless, the industry represents a significant source of employment and income for
          a large number of people in the country. The object of this article is to examine the
          challenges and opportunities of ship breaking industry in Bangladesh and to recommend
          government to pass a new eco-friendly law regarding ship breaking based on the core
          provisions of Basal Convention, so that ship breaking industry in Bangladesh can
          prosper without violating the environmental and labour rights.
        </p>`,
      },
    ],
    pdfPath:
      "https://drive.google.com/file/d/1vK-Qy4TzteS-uG80WqfAPsIWDlMfVyw0/view?usp=sharing",
  },
  {
    id: 5,
    title:
      "The Legal Standpoint of SOLAS Convention for Maritime Safety and Security",
    category: "Analysis",
    image: "/images/all-issues-image/image.jpg",
    images: [
      "/images/all/1.jpg",
      "/images/all/20.jpg",
      "/images/all/13.jpg",
      "/images/all/15.jpg",
    ],
    author:
      "Nishat Tarannum, Lecturer, Bangladesh Maritime University, Bangladesh",
    authorImage: "/images/avatar/users.jpg",
    date: "April 2021",
    isMostview: false,
    // description:
    //   "Struggling to sell one multi-million dollar home quite on currently the market easily dollar home quite.",
    copyright: "© Image Copyrights Title",
    paragraph: [
      {
        id: 1,
        text: "Abstract",
        description: `<p class="" style="text-align: justify;">
          This paper aims to analyze the implication of SOLAS Convention in respect of safety of mercantile
          ships in the present framework of international singularities. Significantly this paper illustrates
          how SOLAS Convention is predominantly imperative in the regime of maintaining the
          international standards about various issues resembling construction of ship, taking precautions
          while navigating as well as having proper lifesaving utilizations, competence of the crew and
          safeguarding competent manning. Consequently, this paper explores the role of flag state for
          ensuring safety of life at sea. Again it elucidates about how the flag states imply and enforce the
          standards of the convention. Moreover in this paper the port state control with regard to
          warranting safety of ships have duly mentioned. As the notion of ‘no more favorable treatment’
          with regard to inspection of ships, detention, maintaining standard as mentioned in the convention
          has profoundly discussed. Furthermore, the most prominent ‘The Estonia Accident case’ has
          designated in this paper. In addition, how loading rules of ships in Bangladesh is associated in
          relation with SOLAS convention has been elaborately stated in this present paper. Lastly the
          dispensed strategies and various guidelines of IMO with concern to ensuring the safe manning
          and safety of life at sea has conversed in this contemporaneous writing.
        </p>`,
      },
    ],
    pdfPath:
      "https://drive.google.com/file/d/1n942OZJs9Wm7E3H7LvF0LBwejtpVAY6-/view?usp=sharing",
  },
  {
    id: 6,
    title:
      "Limited rights of the Borrowers in a Loan Recovery Process under the Money Loan Court Act (Artha Rin Adalat Ain) of Bangladesh",
    category: "Analysis",
    image: "/images/all-issues-image/image.jpg",
    images: [
      "/images/all/1.jpg",
      "/images/all/20.jpg",
      "/images/all/13.jpg",
      "/images/all/15.jpg",
    ],
    author:
      "Barrister Al Amin Rahman and Tasmiah Nuhiya Ahmed, Advocate, Supreme Court of Bangladesh",
    authorImage: "/images/avatar/users.jpg",
    date: "April 2021",
    isMostview: false,
    // description:
    //   "Struggling to sell one multi-million dollar home quite on currently the market easily dollar home quite.",
    copyright: "© Image Copyrights Title",
    paragraph: [
      {
        id: 1,
        text: "Abstract",
        description: `<p class="" style="text-align: justify;">
          Section 19 and Section 41 of the Artha Rin Adalat Ain 2003/ Section 6 and 7 of the Artha Rin
          Adalat Ain 1990 (collectively to be referred as the “Remedial Provisions”) are treating the
          borrowers of banks/financial institutions discriminatingly against the lenders in a loan recovery
          process. Section 19 of the Artha Rin Adalat Ain 2003 provides that a borrower may file an
          application to the court to set aside an ex parte decree through depositing 10% of the total claim
          of the plaintiff bank or financial institution in the concerned financial institution. Section 41 of
          the Artha Rin Adalat Ain 2003 provides that in an Artha Rin suit, if a borrower is the aggrieved
          party, then the borrower can file the appeal application through depositing 50% of the decreetal
          amount in decree holder financial institution. A closure look reveals that these financial
          conditions have actually taken away the rights of the borrowers to file an application to the court
          to set aside an ex parte decree or to file the appeal application even though the law under
          Remedial Provisions apparently provides these rights. Such a deprivation leads to the breach of
          the fundamental right of the borrowers as enshrined under Article 27 & 31 of the Constitution of
          the People’s Republic of Bangladesh. Hence, my paper shall discuss how the borrowers are
          being indirectly prevented from approaching to the courts for filing an application to set aside
          an ex parte decree or for filing an application of appeal against an order or decree passed by
          Artha Rin Adalat due to the financial burden upon them under the Remedial Provisions. The
          paper shall discuss the complaints of the borrowers with regards to the application of the
          Remedial Provisions and responses to those allegations of the borrowers provided by the courts.
          My paper shall also suggest few factors that could have been considered by the Court while
          dealing with borrowers’ claims in this regard in order to avoid any kind of injustice to the
          borrowers in a loan recovery process under Artha Rin Adalat Ain.
        </p>`,
      },
    ],
    pdfPath:
      "https://drive.google.com/file/d/1n942OZJs9Wm7E3H7LvF0LBwejtpVAY6-/view?usp=sharing",
  },
  // 2022
  {
    id: 7,
    title:
      "Implications of the Masdar Hossain case for the independence of Judiciary in Bangladesh",
    category: "Analysis",
    image: "/images/all-issues-image/image.jpg",
    images: [
      "/images/all/1.jpg",
      "/images/all/20.jpg",
      "/images/all/13.jpg",
      "/images/all/15.jpg",
    ],
    author: "Syed Quaiyum Reza Noor, Advocate, Dhaka Judge Court",
    authorImage: "/images/avatar/users.jpg",
    date: "April 2022",
    isMostview: false,
    // description:
    //   "Struggling to sell one multi-million dollar home quite on currently the market easily dollar home quite.",
    copyright: "© Image Copyrights Title",
    paragraph: [
      {
        id: 1,
        text: "Abstract",
        description: `<p class="" style="text-align: justify;">
          Independence of Judiciary is essential element of a democratic government for ensuring rule of
          law. It is certainly a foundation stone of good governance and democratic practice all over the
          world. It protects the weak from the powerful; the minority from the majority; the poor from the
          rich; yes, even the citizens from excesses of government. However, from time immemorial the
          demand for separation of judiciary and judicial independence was a much-debated issue among
          policy reformers, democratic thinkers and legal practitioners in Bangladesh. Since the beginning
          of the British colonial rule, the question of separation of judiciary from the executive has been
          continuing debate. Even after thirty six years of independence, Bangladesh exercises the
          separation of the judiciary from the executive since November 1, 2007. This paper attempts to
          analyze the decision held in the case of – Ministry of Finance, Government of Bangladesh vs. Md.
          Masdar Hossain & others. Through discussion we will observe what were the initiatives mentioned
          in the Masdar Hossain case which are necessary to insure the separation of judiciary from the
          executive. In edition what are the challenges ahead to implement these initiatives in this regard. It
          also discusses briefly the conceptual issue, theory relating to independence of judiciary and finally
          concludes with some recommendations so that goal of separation of judiciary would be effective
          and helpful for the law makers. The paper is based on secondary sources of information.
        </p>`,
      },
    ],
    pdfPath:
      "https://drive.google.com/file/d/1O8iBVBa5Tk5_sABw5EuNPJvTKPsgxX7k/view?usp=sharing",
  },
  {
    id: 8,
    title: "Jallikattu-Regional Pride Versus Animal Welfare",
    category: "Analysis",
    image: "/images/all-issues-image/image.jpg",
    images: [
      "/images/all/1.jpg",
      "/images/all/20.jpg",
      "/images/all/13.jpg",
      "/images/all/15.jpg",
    ],
    author: "Ms. Dafy B. George, RGSOIPL, IIT Kharagpur, India",
    authorImage: "/images/avatar/users.jpg",
    date: "April 2022",
    isMostview: false,
    // description:
    //   "Struggling to sell one multi-million dollar home quite on currently the market easily dollar home quite.",
    copyright: "© Image Copyrights Title",
    paragraph: [
      {
        id: 1,
        text: "Abstract",
        description: `<p class="" style="text-align: justify;">
          This paper relates to the age-old question of where we draw the line between our values,
          customs, rituals, conveniences and ‘what is right’. India is a country that has always
          valued its traditions and customs greatly and kept it at a high pedestal. We have made
          sacrifices and concessions for these customs, which are core to the identity of its people.
          Being the largest democracy in the world ensues constant reminder of ruling in a way
          that is acceptable to the majority and respects the rule of law too. While doing this we
          cannot shrug our responsibility of being respectful to the environment and all that it
          holds. People are growing much more conscious yet agitated when law steps into their
          personal rights. This paper attempts to look at the social ritual of Jallikatu, a popular
          sport of South India that was banned but again reinstated because of social pressure
          from a jurisprudential perspective. A social and legal justification and conflicts have
          been observed by the author to give a better resolution to the issue.
        </p>`,
      },
    ],
    pdfPath:
      "https://drive.google.com/file/d/1htiq0mNIhOEJp3uuBGmvRFLr9kMHxaqC/view?usp=sharing",
  },
  {
    id: 9,
    title:
      "Re-emergence of Delocalization in the Aspect of International Commercial Arbitration",
    category: "Analysis",
    image: "/images/all-issues-image/image.jpg",
    images: [
      "/images/all/1.jpg",
      "/images/all/20.jpg",
      "/images/all/13.jpg",
      "/images/all/15.jpg",
    ],
    author: "Fahad Bin Siddique, Senior Research Officer, BLAST, Bangladesh",
    authorImage: "/images/avatar/users.jpg",
    date: "April 2022",
    isMostview: false,
    // description:
    //   "Struggling to sell one multi-million dollar home quite on currently the market easily dollar home quite.",
    copyright: "© Image Copyrights Title",
    paragraph: [
      {
        id: 1,
        text: "Abstract",
        description: `<p class="" style="text-align: justify;">
          Over the last few decades, the method of international commercial arbitration moves toward
          enhancement. This enhancement process creates harmonization of laws and practice of
          international arbitration all over the world. Nevertheless, the debate between the theory of
          delocalization and localization is still open where the territoriality or localization theory
          constrains the international arbitration while the other theory delocalization liberates the
          international arbitration from all constraint which could create the embargo on its
          implementation. Localization theory could make or lead to serious complications and
          misunderstandings whereas sometimes assistance of the local or national court needed
          during the process of arbitration. In reality, the world community prevails and keen to the
          theory of localization although there are several new systems have been created where the
          law of the local country become baggage. Therefore, the theory of delocalization still plays
          its role in the process of international arbitration. In this circumstances, this theory of
          delocalization become a theory of soft delocalization. In practice, the delocalization theory
          gaining its significance through some new approaches and this could be acknowledged as re-
          emergence of the delocalization theory. Consequently, this paper will focus to analyze the
          debate between two illustrious theories – delocalization and territoriality. This paper will
          also seek to explore the surface of the new approaches of delocalization.
        </p>`,
      },
    ],
    pdfPath:
      "https://drive.google.com/file/d/1sWNWHVXXuPKs0few5Nv9LVaXIsakgNLq/view?usp=sharing",
  },
  {
    id: 10,
    title: "Decolonizing Criminal Law: The Need for a Meaningful Move?",
    category: "Analysis",
    image: "/images/all-issues-image/image.jpg",
    images: [
      "/images/all/1.jpg",
      "/images/all/20.jpg",
      "/images/all/13.jpg",
      "/images/all/15.jpg",
    ],
    author:
      "Shaila Alam Asha, Associate Professor, Department of Law & Justice, Jahangirnagar University, Bangladesh",
    authorImage: "/images/avatar/users.jpg",
    date: "April 2022",
    isMostview: false,
    // description:
    //   "Struggling to sell one multi-million dollar home quite on currently the market easily dollar home quite.",
    copyright: "© Image Copyrights Title",
    paragraph: [
      {
        id: 1,
        text: "Abstract",
        description: `<p class="" style="text-align: justify;">
          The paper is prepared to argue the condition of decolonizing the field of criminal law—a field
          which is particularly important for being the first colonial transplant in this subcontinent. To put
          it another way, this paper is an attempt to locate the colonial elements that can be found in the
          Penal Code 1860 and to give a way forward to decolonizing such elements in the socio-political
          milieu of the twenty first century. It therefore explores the colonial origin of modern criminal law
          in India, which is followed by tracing out importance as well as the ways of decolonizing it.
        </p>`,
      },
    ],
    pdfPath:
      "https://drive.google.com/file/d/1QcMTb_QdNsQca4fQIKm9XzPXqDdf0lYC/view?usp=sharing",
  },
  {
    id: 11,
    title:
      "The Right To Privacy In Bangladesh In The Context Of Technological Advancement",
    category: "Analysis",
    image: "/images/all-issues-image/image.jpg",
    images: [
      "/images/all/1.jpg",
      "/images/all/20.jpg",
      "/images/all/13.jpg",
      "/images/all/15.jpg",
    ],
    // author: "Sadiya S. Silvee1 and Sabrina Hasan2",
    author: (
      <>
        Sadiya S. Silvee<sup>1</sup> and Sabrina Hasan<sup>2</sup>
      </>
    ),
    authorImage: "/images/avatar/users.jpg",
    date: "April 2022",
    isMostview: false,
    // description:
    //   "Struggling to sell one multi-million dollar home quite on currently the market easily dollar home quite.",
    copyright: "© Image Copyrights Title",
    paragraph: [
      {
        id: 1,
        text: "Abstract",
        description: `<p class="" style="text-align: justify;">
          Privacy is a necessity for any human to keep one’s individuality to one’s own. Thus, the right to
          privacy is provided to people through different statutory rules, norms of society and understanding
          among human beings. Over time, ‘the right to privacy’ gets an undisputable status as an absolute
          human right. But, sitting in an era of internet, where technology has advanced in such a manner
          that it has brought a revolution in the manner of communication and information system. In this
          digital era of communication, one is ruining one’s privacy by own sharing behaviour without
          knowing the final fate of the information in this globally connected world. Moreover, as the
          information gets stored in the computer-based system, the chance of a threat to one’s privacy
          remains. In this context, the paper will explore whether being a country of 20th Century ,
          Bangladesh has considered “the right to privacy” as a fundamental right. In doing so, the paper
          will scrutinize how the right is protected under the national laws. In discussing the development
          of ‘the right to privacy” and technology, the paper will argue the importance of protecting the
          right to privacy. In this digital era, where the people have started sharing their information with
          relatively faceless institutions for use- it urges for the security of this personal information.
        </p>`,
      },
    ],
    pdfPath:
      "https://drive.google.com/file/d/1JsST5CH1d3529Ntw37RH3DpJ-QNm3CQ8/view?usp=sharing",
  },
  // 2023
  {
    id: 12,
    title:
      "Judicial Activism in Protection of Child Victims of Sexual Offence in Nepal",
    category: "Analysis",
    image: "/images/all-issues-image/image.jpg",
    images: [
      "/images/all/1.jpg",
      "/images/all/20.jpg",
      "/images/all/13.jpg",
      "/images/all/15.jpg",
    ],
    author:
      "Laxmi Bakhdyo, Associate Professor, Kathmandu School of Law, Nepal",
    authorImage: "/images/avatar/users.jpg",
    date: "April 2023",
    isMostview: false,
    // description:
    //   "Struggling to sell one multi-million dollar home quite on currently the market easily dollar home quite.",
    copyright: "© Image Copyrights Title",
    paragraph: [
      {
        id: 1,
        text: "Abstract",
        description: `<p class="" style="text-align: justify;">
          Justice for children aims to ensure that all children are given proper access to obtain redress in
          criminal and civil matters. The goal for justice for children is to ensure full application of
          international norms and standards for all children who come into contact with juvenile justice
          system and related systems as victims, witnesses, alleged offenders or for other reasons where
          judicial, state-administrative or non-state adjudicatory interventions are needed. Judiciary is
          guardian for the human rights protection of each citizen. Judiciary, in context of Nepal, has
          played vital role on protection of rights of children victims of sexual violence as well. Children
          are found to be victimized of sexual violence and are prone to such violence as they are not in
          state to foresee the situation, consequence and they are not in state to differentiate between
          sexual abuse and love.
        </p>`,
      },
    ],
    pdfPath:
      "https://drive.google.com/file/d/1wxYqhKoiBkeD6paVs6I5lvFzY9Ji_edw/view?usp=sharing",
  },
  {
    id: 13,
    title:
      "14 Years Battle Behind the Bars for Justice: State vs. Sukur Ali Revisited",
    category: "Analysis",
    image: "/images/all-issues-image/image.jpg",
    images: [
      "/images/all/1.jpg",
      "/images/all/20.jpg",
      "/images/all/13.jpg",
      "/images/all/15.jpg",
    ],
    author:
      "Sadiya S. Silvee, Doctoral Student, Law School of Zhengzhou University, China",
    authorImage: "/images/avatar/users.jpg",
    date: "April 2023",
    isMostview: false,
    // description:
    //   "Struggling to sell one multi-million dollar home quite on currently the market easily dollar home quite.",
    copyright: "© Image Copyrights Title",
    paragraph: [
      {
        id: 1,
        text: "Abstract",
        description: `<p class="" style="text-align: justify;">
          Offences committed against women and children in Bangladesh are tried under Nari-O-
          Shishu Nirjatan Daman Ain, 2000. Under this Act, ‘a child’ is defined within the meaning of
          the Children Act, 1974 (as repealed and replaced in 2013) and included as a victim along
          with women. What happens if a child under the age of sixteen years commits a crime
          punishable under Nari-O-Shishu Nirjatan Ain, 2000? A similar question was raised before
          the Nari-O-Shishu Nirjatan Daman Bishesh Adalat, established under section 6(2) of, then,
          Nari-O-Shishu Nirjatan (Bishesh Bidhan) Ain, 1995. Despite having approximately hundred
          and sixty-eight years old juvenile justice system, while trying the case State vs. Sukur Ali, the
          Bishesh Adalat (Special Tribunal) has convicted the accused juvenile Sukur Ali with death
          penalty. Affirming Sukur Ali’s death penalty, the High Court upheld the conviction on the
          ground that Section 3 of the relevant law was a ‘non-obstante clause’ and has an overriding
          effect. Hence, the provisions of Nari-O-Shishu Nirjatan (Bishesh Bidhan) Ain, 1995 prevails
          over the Children Act, 1974. This paper addresses the question of jurisdiction of the Nari-O-
          Shishu Nirjatan Daman Bishesh Adalat to try the juvenile offender Sukur Ali. Referring Nari-
          O-Shishu Nirjatan Ain, 1995, the Children Act, 1974 is as ‘constitutional statute’ as both the
          laws are promulgated as a direct manifestation of the “Fundamental Rights” prescribed
          under Article 28(4) of the Constitution, the paper analysis whether Sukur Ali’s trial
          appreciate the true spirit of relevant constitutional provisions so as to ensure that
          accused/convict has been tried according to law and only according to law as ensured under
          Article 31 of the Constitution.The paper also observed the various actors/ stakeholders of
          judicial system who plays the vital role to uphold the true spirit of relevant constitutional
          provisions in order to ensure justice; not only for victims but also for the accused. Any
          deviation from the constitutional clause may, in turn, vitiate the whole proceeding from its
          inception.
        </p>`,
      },
    ],
    pdfPath:
      "https://drive.google.com/file/d/1M1e2R1_bQHMytr_r9MAw6radpcgYLUZT/view?usp=sharing",
  },
  {
    id: 14,
    title: "Looking at Rape in India Through A Gender-Sensitive Lens",
    category: "Analysis",
    image: "/images/all-issues-image/image.jpg",
    images: [
      "/images/all/1.jpg",
      "/images/all/20.jpg",
      "/images/all/13.jpg",
      "/images/all/15.jpg",
    ],
    author:
      "Ashna D and Dharshini Sugumaran, Law Students (LLB Program), India",
    authorImage: "/images/avatar/users.jpg",
    date: "April 2023",
    isMostview: false,
    // description:
    //   "Struggling to sell one multi-million dollar home quite on currently the market easily dollar home quite.",
    copyright: "© Image Copyrights Title",
    paragraph: [
      {
        id: 1,
        text: "Prelude",
        description: `<p class="" style="text-align: justify;">
         In this so called progressive age, where our laws are meant to be moulded according to the
          changes taking place in society, we must ask ourselves how fluid and adaptable these laws
          actually are. Today, the number of rapes in India is alarmingly high. While our laws have
          taken adequate measures to safeguard the interests and bodily integrity of women, they have
          turned a blind eye to the victims of rape belonging to other genders. This legal note attempts
          to urge our law makers to look at rape through a gender-sensitive lens. The absence of
          having such a perspective is a gross violation of the fundamental rights of the victims. This
          becomes all the more necessary in the light of the recent landmark judgement that has now
          legalised same gender sex in the country. This legal note also seeks to do away with the
          popular yet incorrect view that finds it impossible to recognize women as perpetrators of
          rape. The authors hope to instil in the minds of their readers that any individual can be raped
          by any other, irrespective of their genders. It is about time that our rape laws adapt a gender-
          sensitive approach that ensures every single individual equal protection under the law.
        </p>`,
      },
    ],
    pdfPath:
      "https://drive.google.com/file/d/1Xjaf0txZJrolTfCZR9vvzXj3z1zghQdd/view?usp=sharing",
  },
  {
    id: 15,
    title:
      "Some provisions of criminal law itself sometimes encourage violence against women",
    category: "Analysis",
    image: "/images/all-issues-image/image.jpg",
    images: [
      "/images/all/1.jpg",
      "/images/all/20.jpg",
      "/images/all/13.jpg",
      "/images/all/15.jpg",
    ],
    author:
      "Bushrat Jahan Tandra, Doctoral Student, Faculty of Law, University of Pecs, Hungary",
    authorImage: "/images/avatar/users.jpg",
    date: "April 2023",
    isMostview: false,
    // description:
    //   "Struggling to sell one multi-million dollar home quite on currently the market easily dollar home quite.",
    copyright: "© Image Copyrights Title",
    paragraph: [
      {
        id: 1,
        text: "Prelude",
        description: `<p class="" style="text-align: justify;">
         This legal note is an attempt to describe various forms of violence against women in our society.
          This paper also raises the issues inherent in considering the complexities of violence and present
          cases of resistance against violence. This paper also focuses on some insensitive provisions of
          criminal laws for women and some lacunas of some provisions of criminal law.
          In the context of Bangladesh it is deeply embedded in cultural and socio-economic practices,
          violence against women is sanctioned by both society and the state, in the name of culture,
          tradition and religion. Some laws have been introduced to protect the women rights, injustice
          made to them and those are still in action. But for some lacunas of some provisions of those laws
          sometimes cannot prevent the violence against women rather make space for the wrong doer for
          escape.
        </p>`,
      },
    ],
    pdfPath:
      "https://drive.google.com/file/d/19FqhsRfmdyBRmbDWomAPiIjlzt_rvskX/view?usp=sharing",
  },
  {
    id: 16,
    title: (
      <>
        Stalking: Liability under the Existing Law of Bangladesh<sup>1</sup>
      </>
    ),
    category: "Analysis",
    image: "/images/all-issues-image/image.jpg",
    images: [
      "/images/all/1.jpg",
      "/images/all/20.jpg",
      "/images/all/13.jpg",
      "/images/all/15.jpg",
    ],
    author: (
      <>
        Nazmun Nahar, Advocate, Dhaka Judge Court, Bangladesh<sup>2</sup>
      </>
    ),
    authorImage: "/images/avatar/users.jpg",
    date: "April 2023",
    isMostview: false,
    // description:
    //   "Struggling to sell one multi-million dollar home quite on currently the market easily dollar home quite.",
    copyright: "© Image Copyrights Title",
    paragraph: [
      {
        id: 1,
        text: "Abstract",
        description: `<p class="" style="text-align: justify;">
         There are many new social problems arising with the development of society and technology.
          Stalking is one of the significant problems around the world. Bangladesh is no exception to
          this. Anti-stalking law has been introduced in most of the countries around the world. The
          High Court Division of the Supreme Court of Bangladesh in a judgment in 2011 provided
          several directives, although, Bangladesh is yet to amend or enact any law regarding stalking.
          Hence, in my paper I have described what stalking stands for and, later on I have described
          the scope of existing laws and its loophole regarding this mater. Before that, to give the
          background idea to the readers I have accordingly stated the history and overview of
          ‘stalking’ in Bangladesh.
        </p>`,
      },
    ],
    pdfPath:
      "https://drive.google.com/file/d/19C4JwQXrbAgR5RGI7vF7nUaCQ3RHUY9z/view?usp=sharing",
  },
  {
    id: 17,
    title: "Sustainable Development: Prospects of Bangladesh",
    category: "Analysis",
    image: "/images/all-issues-image/image.jpg",
    images: [
      "/images/all/1.jpg",
      "/images/all/20.jpg",
      "/images/all/13.jpg",
      "/images/all/15.jpg",
    ],
    author:
      "Fahad Bin Siddique, UMSAILS fellow, LL.M. in Human Rights (Jointly organized by UMSAILS & University of Asia Pacific)",
    authorImage: "/images/avatar/users.jpg",
    date: "April 2023",
    isMostview: false,
    // description:
    //   "Struggling to sell one multi-million dollar home quite on currently the market easily dollar home quite.",
    copyright: "© Image Copyrights Title",
    paragraph: [
      {
        id: 1,
        text: "Abstract",
        description: `<p class="" style="text-align: justify;">
          Sustainable development is a well-established concept now. Every sector of environment
          establishment has appreciated sustainable development as a concept. The development of this
          concept and establishment of this theory by the world leader was difficult, but the vision was
          to save the world from environmental degradation. This theory makes an impact to create a
          historic north-south debate on environment and in Millennium development goals. After that,
          the Sustainable development goal and the Paris Climate treaty are also created by an impact
          of this concept. Now in the case of implementation, some developing countries are doing well
          to achieve these goals and keep the commitment on the environment and the international
          community. Bangladesh is also doing well in achieving these goals and makes the world
          environment-friendly. After the end of the Millennium development goals now, Bangladesh is
          on the way to achieving the Sustainable development goals also. However, with the proper
          working plans and guidelines, political interest and situation also need to be considered in
          achieving these goals.
        </p>`,
      },
    ],
    pdfPath:
      "https://drive.google.com/file/d/1uUJn_IlFs5mNYFH2ByHdADaY-xsvuMN3/view?usp=sharing",
  },
  {
    id: 18,
    title:
      "The Jurisprudence of Positive Discrimination in the Context of Bangladesh: Grace or Right?",
    category: "Analysis",
    image: "/images/all-issues-image/image.jpg",
    images: [
      "/images/all/1.jpg",
      "/images/all/20.jpg",
      "/images/all/13.jpg",
      "/images/all/15.jpg",
    ],
    author: "Md. Fahim Ahsan, Advocate, Dhaka Judge Court, Bangladesh",
    authorImage: "/images/avatar/users.jpg",
    date: "April 2023",
    isMostview: false,
    // description:
    //   "Struggling to sell one multi-million dollar home quite on currently the market easily dollar home quite.",
    copyright: "© Image Copyrights Title",
    paragraph: [
      {
        id: 1,
        text: "Abstract",
        description: `<p class="" style="text-align: justify;">
          This paper is prepared to analyze the importance of quota system and the current situation of
          quota system of Bangladesh. Most of the developing and also developed countries are preserving
          special reservation for backward people in government job. On the other hand the government of
          Bangladesh is trying to abolish the whole reservation process in government job. The
          constitution of Bangladesh is supporting the quota system for those backward groups.
          International instruments are also supporting special preservation for certain group of people.
          Domestic and International instruments always support equal opportunity and rights, which is
          important to create a level playing field. This paper will analyze the importance of positive
          discrimination and overall quota system of Bangladesh.
        </p>`,
      },
    ],
    pdfPath:
      "https://drive.google.com/file/d/1i5A495a32swhs3egiZ1JgjYNcQV1wIRy/view?usp=sharing",
  },
  // 2024
  {
    id: 19,
    title:
      "Analysis and Challenges of Resolving Commercial Disputes Through Commercial Arbitration",
    category: "Analysis",
    image: "/images/all-issues-image/image.jpg",
    images: [
      "/images/all/1.jpg",
      "/images/all/20.jpg",
      "/images/all/13.jpg",
      "/images/all/15.jpg",
    ],
    author:
      "Zahid Omarzai, LL.M. Graduate, School of Law, University of Pittsburgh, and Executive Director, Afghanistan Center for Dispute Resolution, Afghanistan",
    authorImage: "/images/avatar/users.jpg",
    date: "April 2024",
    isMostview: false,
    // description:
    //   "Struggling to sell one multi-million dollar home quite on currently the market easily dollar home quite.",
    copyright: "© Image Copyrights Title",
    paragraph: [
      {
        id: 1,
        text: "Abstract",
        description: `<p class="" style="text-align: justify;">
          This paper tackles one of the most pressing needs of Afghanistans commercial sector: resolving
          commercial disputes through arbitration. Commercial arbitration is the alternative dispute
          resolution method for resolving commercial disputes through arbitration by a neutral tribunal.
          The parties to a dispute will have the authority to appoint one arbitrator each and then the parties
          appointed arbitrators will appoint the presiding arbitrator. Furthermore, they can choose the
          governing laws of their disputes. And, considering their needs, choose to resolve their commercial
          dispute through either ad hoc or institutional arbitration. This paper is consist of six chapters.
          Adequately analyzing and an introductory approach to various aspects of International
          Commercial Arbitration and its essential characteristics so the reader can have a clear view
          regarding the same. Chapter 2 consists of Background, Legal and Institutional Framework which
          gives an insight to the reader about legal aspect, framework and background of International
          Commercial Arbitration which is equally important as the one mentioned about. To better analyze
          challenges and advantages of International Commercial Arbitration it is important to link with
          business and commerce as to have more in-depth look into such issues in business world. For the
          reason mentioned, chapter 3 consists of the same with the title “ADR and Business”. Furthermore,
          for a more detailed study of challenges and advantages of international commercial arbitration
          and Alternative Dispute Resolution (“ADR”) chapter 4 and 5 of this paper has been added for the
          same. Proceeding with chapter 6 which consists of two surveys giving a very practical and very
          useful approach for the reader to compare the statistics to what has been said in theory. As the
          structure demands, this paper also includes an overall conclusion to all that have been written in
          the abovementioned chapters and whole research which has been carried out to make this paper.
          And after pointing out the challenges, this paper consist of practical approaches and
          recommendations which can be implemented to overcome the challenges. It is also worthy to be
          mentioned that, all the resources of this paper are from the most prominent and the most reliable
          resource of International Commercial Arbitration which is “Kluwer Arbitration” having the books
          and other materials written by the leading scholars in field of International Commercial
          Arbitration.
        </p>`,
      },
    ],
    pdfPath:
      "https://drive.google.com/file/d/18Pf9yWIvGh--ci0R0IFf7iNvwTRGHm54/view?usp=sharing",
  },
  {
    id: 20,
    title:
      "Freedom of Speech and Contempt of Court: A Continuous Clash Between A Fundamental Right and Dignity of the Judiciary",
    category: "Analysis",
    image: "/images/all-issues-image/image.jpg",
    images: [
      "/images/all/1.jpg",
      "/images/all/20.jpg",
      "/images/all/13.jpg",
      "/images/all/15.jpg",
    ],
    author: "Noor Mohammed Bhuiyan, Senior Judicial Magistrate, Bangladesh",
    authorImage: "/images/avatar/users.jpg",
    date: "April 2024",
    isMostview: false,
    // description:
    //   "Struggling to sell one multi-million dollar home quite on currently the market easily dollar home quite.",
    copyright: "© Image Copyrights Title",
    paragraph: [
      {
        id: 1,
        text: "Abstract",
        description: `<p class="" style="text-align: justify;">
          Freedom of speech and expression and freedom of press are fundamental rights which are
          universally acknowledged. These rights may conflict with the administration of justice and there
          should be a check and balance between them. Fair and constructive criticism is praised by the
          courts but there is no specific definition of fair criticism, it may vary according to the surroundings
          of a particular case. The courts are strict about the practice of freedom of speech regarding a
          pending matter as it may influence mind of the people and prejudice parties on that cause which
          ultimately affect the confidence of people upon judiciary. However, the courts are more liberal
          regarding the criticism about them as the growing demand of freedom of speech is gaining support
          worldwide. A well defined law and honest journalism are required to deal with the matter and
          make balance between them which are absent in our country.
        </p>`,
      },
    ],
    pdfPath:
      "https://drive.google.com/file/d/1_b7C_FQEXED-vO5NawF54LgxvIlIgvXe/view?usp=sharing",
  },
  {
    id: 21,
    title:
      "Bangladesh Persecution on Homosexuality: A picture of intolerance and discrimination against Homosexuals",
    category: "Analysis",
    image: "/images/all-issues-image/image.jpg",
    images: [
      "/images/all/1.jpg",
      "/images/all/20.jpg",
      "/images/all/13.jpg",
      "/images/all/15.jpg",
    ],
    author: "Aditi Bhattacharjee, B.S.L (LL.B), Pune University, India",
    authorImage: "/images/avatar/users.jpg",
    date: "April 2024",
    isMostview: false,
    // description:
    //   "Struggling to sell one multi-million dollar home quite on currently the market easily dollar home quite.",
    copyright: "© Image Copyrights Title",
    paragraph: [
      {
        id: 1,
        text: "Abstract",
        description: `<p class="" style="text-align: justify;">
          Sexual minorities are the most deprived and humiliated people in our society. There are a group
          of people whose sexual orientation is different from others. This research paper will shed light
          on the sensitive issue Homosexuality and problems faced by homosexuals in our country. This
          paper will also highlight the schooling experiences, peer influences, homophobic attitude and
          society’s stigma towards homosexuals. There are theoretical approaches that explain the issues
          faced by the Homosexuals. We are living in a society where we can tolerate people who are
          committing adultery or beating their counterparts but we can’t tolerate those who are different
          from us. Homosexuality depends on ones genetically dysfunction not because of peer influence.
          Homosexuals exist in our society but they are always ignored. We need to open up and consider
          them as a part of our society. As a citizen of Bangladesh, they have freedom of expression and
          have the rights of living their own life. According to the existing data there are 5 to 10%
          homosexuals in our country. United Nations Human Rights Council expressed concern over hate
          crimes against sexually minorities all over the world. Nobel Laureate Dr. Mohammad Yunus
          gave statement to legalize same sex relationship. Public figures Like Mr. Yunus and other Social
          worker, Human Right Activist and Media Personality should raise voice against victimization of
          Homosexuals. The attack on homosexual people is slowly increasing. The exploitation and
          harassment against sexual minorities need to be removed from the society. We must firmly
          support the activities led by NGO’s and Human Rights Activists for their enormous effort to
          ensure legal rights for sexual minorities of our society. This research paper is based on
          discrimination against Homosexuals in our society and develops effective strategies to mitigate
          the problems.
        </p>`,
      },
    ],
    pdfPath:
      "https://drive.google.com/file/d/1eOmHUuhnTQSu6qwc7YiRa3ne4Ap-HNG3/view?usp=sharing",
  },
  {
    id: 22,
    title:
      "Divorce Right-Determinant of Divorce of All Communities in Bangladesh",
    category: "Analysis",
    image: "/images/all-issues-image/image.jpg",
    images: [
      "/images/all/1.jpg",
      "/images/all/20.jpg",
      "/images/all/13.jpg",
      "/images/all/15.jpg",
    ],
    author:
      "Afsana Akter, Coordinator (legal support), Bandhu Social Welfare Society",
    authorImage: "/images/avatar/users.jpg",
    date: "April 2024",
    isMostview: false,
    // description:
    //   "Struggling to sell one multi-million dollar home quite on currently the market easily dollar home quite.",
    copyright: "© Image Copyrights Title",
    paragraph: [
      {
        id: 1,
        text: "1. Introduction",
        description: `<p class="" style="text-align: justify;">
          Marriage is the sacred relationship of human being in which they find real happiness. Life is
          not fair to everyone. A happy married life is blessing at the same time being in gloomy
          married life is curse. Divorce is the only way to terminate the marital tie between two living
          person, and cancelling all responsibilities and legal duties to each other. The process of
          divorce not only dissolves the conjugal relationship along with that it settles dower money,
          maintenance, child custody etc.According to the Bangladesh Bureau of Statistics (BBS)
          report, divorce rate increased almost doubled over the last decade.1 It’s not always that both
          parties are happy with the decision of divorce. This might happen that only one party become
          desperate to dissolve the marriage while others tried his or her best to continue the conjugal
          relationship. Divorce has both positive and negative aspects. There is nothing worse for a
          child who belong from a broken family. Considering all situations, it’s better to get divorce
          rather than being in a toxic marital relationship.
        </p>`,
      },
    ],
    pdfPath:
      "https://drive.google.com/file/d/1HAXop5bbyTazBOTmcR1amb2LLGvCsBJz/view?usp=sharing",
  },
  {
    id: 23,
    title: "Systematic Erosion of the Indigenous People's Right in Bangladesh",
    category: "Analysis",
    image: "/images/all-issues-image/image.jpg",
    images: [
      "/images/all/1.jpg",
      "/images/all/20.jpg",
      "/images/all/13.jpg",
      "/images/all/15.jpg",
    ],
    author: "Nazmun Nahar, Human Rights Defender and Lawyer",
    authorImage: "/images/avatar/users.jpg",
    date: "April 2024",
    isMostview: false,
    // description:
    //   "Struggling to sell one multi-million dollar home quite on currently the market easily dollar home quite.",
    copyright: "© Image Copyrights Title",
    paragraph: [
      {
        id: 1,
        text: "Introduction",
        description: `<p class="" style="text-align: justify;">
          More than sixty years have passed since the adoption of the first international document on
          indigenous rights in 1957.1 Since then, several non-binding documents have been introduced.2
          However, the international community has yet to produce a binding legal instrument that
          secures the rights of indigenous peoples under international law. This exclusion of indigenous
          peoples from the protection afforded by international law has also persisted within national
          legal systems.
          In this article, I will examine the systematic erosion of indigenous peoples' rights in
          Bangladesh. Over the years, political, legal, and linguistic exclusion has marginalized these
          communities and disregarded their rights. The monolingual and monocultural framework of
          the Constitution of Bangladesh has rendered their civil and political rights invisible. I will begin
          by exploring the existence and recognition of indigenous peoples in Bangladesh, followed by
          an analysis of how systemic erosion of their rights has occurred through pre- and post-colonial
          political, linguistic, and legal exclusion of their identity and rights.
        </p>`,
      },
    ],
    pdfPath:
      "https://drive.google.com/file/d/12wLHnceF97BP9JV-1HlFltrn9_mZEJUs/view?usp=sharing",
  },
  {
    id: 24,
    title: "The Environmental Protection in Pakistan and Legal Developments",
    category: "Analysis",
    image: "/images/all-issues-image/image.jpg",
    images: [
      "/images/all/1.jpg",
      "/images/all/20.jpg",
      "/images/all/13.jpg",
      "/images/all/15.jpg",
    ],
    author: "Seetal Das, South Asian University Alumni, Pakistan",
    authorImage: "/images/avatar/users.jpg",
    date: "April 2024",
    isMostview: false,
    // description:
    //   "Struggling to sell one multi-million dollar home quite on currently the market easily dollar home quite.",
    copyright: "© Image Copyrights Title",
    paragraph: [
      {
        id: 1,
        text: "Introduction",
        description: `<p class="" style="text-align: justify;">
          The Development of Environmental Law in Pakistan began when the Stockholm Declaration came
          into existence in 1972. Pakistan has developed the environmental law in the National as well as the
          Provincial levels. Pakistan is party to a number of treaties the regional as well as the global basis. As
          a developing country, there is rapidly changing of environmental law.
          The first environmental legislation came into existence in 1983 which was the Pakistan
          Environmental Protection Ordinance 1983.It worked for one and half decades because it could not
          be properly implemented and enforced. The environmental pollution has been increased day by day.
          Pakistan has not been able to protect the environment and eliminate the degradation, water pollution,
          municipal pollution, hospital waste, agriculture waste. A strong legislative cover is available for
          dealing with diverse environmental areas like forests, health, hazardous substances, and use of water,
          soils and lands.
        </p>`,
      },
    ],
    pdfPath:
      "https://drive.google.com/file/d/1d4dHw-63esyVh81ieKiGsoDEcOrW2uYo/view?usp=sharing",
  },
  // 2025
  {
    id: 25,
    title:
      "Neutrality at Crisis: A Legal Appraisal in Light of Contemporary International Law",
    category: "Analysis",
    image: "/images/all-issues-image/image.jpg",
    images: [
      "/images/all/1.jpg",
      "/images/all/20.jpg",
      "/images/all/13.jpg",
      "/images/all/15.jpg",
    ],
    author:
      "Niaz Mohammad, Legal Research Assistant, Center for Peace and Justice, BRAC University",
    authorImage: "/images/avatar/users.jpg",
    date: "April 2025",
    isMostview: false,
    // description:
    //   "Struggling to sell one multi-million dollar home quite on currently the market easily dollar home quite.",
    copyright: "© Image Copyrights Title",
    paragraph: [
      {
        id: 1,
        text: "Abstract",
        description: `<p class="" style="text-align: justify;">
          The rules of neutrality formally entered the corpus of international law in 1907 through the
          promulgation of Hague Convention V on respecting the Rights and Duties of Neutral Powers
          and Persons in Case of War on Land and the rules of neutrality in the present world still heavily
          rely on the rules that were propounded before the World War I. Since a new legal order
          emerged with the creation of the League of Nations and its successor, the United Nations, the
          rules of neutrality appear to be obsolete in the face of modern-day armed conflicts. Amid the
          reincarnation of a world forum following World War II i.e. the UN, other international,
          regional organizations, and varying breadth of sovereign prerogative exercised by the States
          under international law giving rise to complex international legal order, it is time to examine
          the relevant historical events as well as the key legal jurisprudence in question to determine
          how much of the law of neutrality has survived the test of time and the implications of it in the
          ever-changing landscape of international legal framework.
        </p>`,
      },
    ],
    pdfPath:
      "https://drive.google.com/file/d/1mSwimyt7FBSxZnHaGYv3bk9UE-2jvFJ3/view?usp=sharing",
  },
  {
    id: 26,
    title:
      "Recognition of Nationality under Consular Law: Legality of Applying the Laws and Regulations of The Receiving State to Pursue Consular Relations",
    category: "Analysis",
    image: "/images/all-issues-image/image.jpg",
    images: [
      "/images/all/1.jpg",
      "/images/all/20.jpg",
      "/images/all/13.jpg",
      "/images/all/15.jpg",
    ],
    author: "Taskin Tanika, Member, American Society of International Law",
    authorImage: "/images/avatar/users.jpg",
    date: "April 2025",
    isMostview: false,
    // description:
    //   "Struggling to sell one multi-million dollar home quite on currently the market easily dollar home quite.",
    copyright: "© Image Copyrights Title",
    paragraph: [
      {
        id: 1,
        text: "Abstract",
        description: `<p class="" style="text-align: justify;">
          Recognition of nationality is the core essential for the pursuit of consular relations under the
          Vienna Convention as it is the fundamental of the legal bond among states for the individual,
          however, VCCR does not address the matter. VCCR states consular function shall be exercised in
          conformity with the laws of the receiving state (RS). Therefore, disputes arise on non-recognition
          of nationality by the RS, which leads to the conflict of the sovereign equality of both sending state
          (SS) and RS. Denial to invoke consular access, on the basis of non-recognition of nationality by
          the RS, deprives the individuals from availing rights that are assured under the VCCR, thereby,
          the rights of SS is also being curtailed. Simultaneously, RS violates its international the obligations
          under VCCR. In such legal dissension, the central question of this research paper is, can the RS
          apply its laws and regulations to recognise the nationality of the individual for whom the consular
          function shall be exercised by a SS under the VCCR. This paper has initially disclosed that the
          legal differences among states on recognition of nationality has resulted because the matter has
          been vaguely discussed while forming VCCR. In order to dissolve the matter, the author has
          addressed the emerging customary international law in which a RS has the obligation to recognise
          the nationality of an individual. Furthermore, it has examined that the consequence of non-
          recognition shall be the violation of VCCR. The study has specifically considered the
          interpretation given by ICJ in the cases- Nottebohm, LaGrand, Avena and other Mexican
          Nationals and Jadhav on recognition of nationality. Finally, the author concludes that the laws
          and regulation of the RS shall be applied in a manner to recognise the nationality so that the
          unimpeded conduct of consular protection can be invoked.
        </p>`,
      },
    ],
    pdfPath:
      "https://drive.google.com/file/d/1pwi3giPOWE8Ny0LAuJqsWQDtRPc1NfXp/view?usp=sharing",
  },
  {
    id: 27,
    title:
      "From Constitutional Safeguard to State Control: The Misapplication of Article 33 of The Constitution of the People's Republic of Bangladesh",
    category: "Analysis",
    image: "/images/all-issues-image/image.jpg",
    images: [
      "/images/all/1.jpg",
      "/images/all/20.jpg",
      "/images/all/13.jpg",
      "/images/all/15.jpg",
    ],
    author:
      "Md. Ajmain Inkyad Bhuiyan, LLM Student, Bangladesh University of Professionals",
    authorImage: "/images/avatar/users.jpg",
    date: "April 2025",
    isMostview: true,
    // description:
    //   "Struggling to sell one multi-million dollar home quite on currently the market easily dollar home quite.",
    copyright: "© Image Copyrights Title",
    paragraph: [
      {
        id: 1,
        text: "Abstract",
        description: `<p class="" style="text-align: justify;">
          The formation of Article 33 of the Constitution of Bangladesh was aimed at preventing the misuse
          of citizens’ fundamental rights by way of arbitrary arrest and detention. This reiterates the love of
          the nation to uphold the civil rights of the citizens. However, in the course of time, the provision’s
          broad usage has been far from its original aim hence making it a resource that has abused a
          number of individuals especially those who oppose the political regime or are deemed to be a
          danger to the state. This research seeks to understand how the states Article 33 as criticism
          towards the law in Bangladesh including the role of sweeping fair legal practice, enforcement
          overuses, and legislative darkness has turned Article 33 from an article of protection to an article
          of submission to the state. The investigation of administrative form of legislation, cases Diego
          Lopez and Jazmine strictures and those extent of Article 33 provision do underline the causes of
          infringement of this Article. Such disfunction, that has seemed to be a regulation drain could
          resolve protection of individual rights Whereas, issues as the growing monopoly of power among
          the ruling elite colors tolerance of law that promotes both security and restrains power. Such
          specific deficiencies in legal provisions and their effects on practice are examined and discussed
          with a view to making proposals for legislative changes and policy measures aimed at containing
          the destructive tendency of reverting Article 33 to its original dome ensuring its effective
          enforcement in safeguarding citizens’ rights.
        </p>`,
      },
    ],
    pdfPath:
      "https://drive.google.com/file/d/1Q3LdEccBfviMKNLKTqTyL40kOu6Ti-tX/view?usp=sharing",
  },
  {
    id: 28,
    title:
      "Prevention of Cyber Harassment from the Feminist Perspective in the Context of Bangladesh",
    category: "Analysis",
    image: "/images/all-issues-image/image.jpg",
    images: [
      "/images/all/1.jpg",
      "/images/all/20.jpg",
      "/images/all/13.jpg",
      "/images/all/15.jpg",
    ],
    author: "Musfera Jannat, School of Law, BRAC University",
    authorImage: "/images/avatar/users.jpg",
    date: "April 2025",
    isMostview: true,
    // description:
    //   "Struggling to sell one multi-million dollar home quite on currently the market easily dollar home quite.",
    copyright: "© Image Copyrights Title",
    paragraph: [
      {
        id: 1,
        text: "Abstract",
        description: `<p class="" style="text-align: justify;">
          The increasing incidences of cyber-harassment against women have emerged as a significant
          threat in the global community as well as in Bangladesh. As time passes, cyber harassment against
          women becomes the most distressing experience in Bangladesh. As per CID statistics, the average
          monthly cybercrime complaints they used to receive in the past was around 2,500. The number
          has now increased to 3,500. However, this paper seeks to explore the various forms of cyber
          harassment against women in Bangladesh and analyze the potential legal framework from the
          feminist perspective on how they are trying to mitigate cyber harassment against women in
          Bangladesh. It will also examine the prevention of cyber harassment with the effectiveness of
          existing laws and regulations. This research will also talk about the consequences of cyber
          harassment and how it affects their well-being. Additionally, the paper argues the question, what
          should be the key strategy, feminist principles and legal preventive measures against cyber
          harassment in Bangladesh, and how effective is Bangladesh’s current legal system at tackling
          cyber harassment against women in terms of offering legal protection through a feminist lens?
          Lastly, the study will suggest certain ways or recommendations and practical ideas to strengthen
          the cyber security measures for mitigating cyber harassment from the feminist perspective with an
          emphasis on safeguarding cyberspace in Bangladesh.
        </p>`,
      },
    ],
    pdfPath:
      "https://drive.google.com/file/d/1JpGS4hAdWZxfCWV_W31VfxjywLTIKceI/view?usp=sharing",
  },
  {
    id: 29,
    title:
      "The Significance of Ombudsman in the Current Administrative Structure in Bangladesh: A Legal Analysis",
    category: "Analysis",
    image: "/images/all-issues-image/image.jpg",
    images: [
      "/images/all/1.jpg",
      "/images/all/20.jpg",
      "/images/all/13.jpg",
      "/images/all/15.jpg",
    ],
    author:
      "Nafis Chowdhury (LLM Student, Bangladesh University of Professionals) and Md. Siam Shafi (LLB Student, School of Law, BRAC University)",
    authorImage: "/images/avatar/users.jpg",
    date: "April 2025",
    isMostview: true,
    // description:
    //   "Struggling to sell one multi-million dollar home quite on currently the market easily dollar home quite.",
    copyright: "© Image Copyrights Title",
    paragraph: [
      {
        id: 1,
        text: "Abstract",
        description: `<p class="" style="text-align: justify;">
          An Ombudsman in administrative law is an independent official or body appointed by the
          government to oversee and investigate complaints and grievances against administrative actions
          and decisions made by government agencies, departments or public officials. The primary role of
          the Ombudsman office is to protect individuals from violations of their rights by the government,
          abuse of power, errors, negligence, unfair decisions, and maladministration. The key aspect of
          this research is to critically analyze the concept of Ombudsman, assess the effectiveness of
          Ombudsman in some developed and developing countries and most importantly- its significance
          to reform the current administrative structure in Bangladesh. In this research, it is argued that the
          existing laws of Bangladesh concerning Ombudsman are inadequate and so significant
          amendments are required before establishing the office of the Ombudsman. After an introduction
          in Chapter I, the authors have discussed the historical background of the Ombudsman to
          understand its significance in chapter II. In Chapter III, the historical context of Bangladesh and
          the existing legislative framework of Ombudsman is critically analyzed. In Chapter IV, the
          rationale of having an Ombudsman in Bangladesh is mentioned. In this chapter, it is argued how
          the current issues within the administrative structure can benefit from the Ombudsman office and
          what amendments are required within the existing laws of Bangladesh by studying the laws of
          some developed and developing countries. Lastly, chapter V offers concluding remarks.
        </p>`,
      },
    ],
    pdfPath:
      "https://drive.google.com/file/d/19Lbma7k37Dx8lldluTjilTEgcX247eSC/view?usp=sharing",
  },
];

export default blogData;
