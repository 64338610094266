// import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
// import { useEffect, useState } from "react";
// import Header from "./components/Header/Header";
// import ScrollUp from "./components/ScrollUp";
// import Top from "./components/Top";
// import NotFound from "./components/NotFound";
// import Footer from "./components/Footer/Footer";
// import Loader from "./components/Loader";

// import Home from "./pages/Home/Home";
// import AllIssues from "./pages/AllIssues/AllIssues";
// import AllIssuesDetails from "./pages/AllIssuesDetails/AllIssuesDetails";
// import JournalOverview from "./pages/JournalOverview/JournalOverview";
// import EditorialPolicy from "./pages/EditorialPolicy/EditorialPolicy";
// import SubmissionGuidelines from "./pages/SubmissionGuidelines/SubmissionGuidelines";
// import Contact from "./pages/Contact/Contact";
// import Login from "./pages/LoginRegistration/Login";
// import Registration from "./pages/LoginRegistration/Registration";

// function AppContent() {
//   const location = useLocation();
//   const [loading, setLoading] = useState(false);

//   useEffect(() => {
//     // Start loader on location change
//     setLoading(true);

//     // Fake delay for loader, can be removed if using real data loading
//     const timer = setTimeout(() => {
//       setLoading(false);
//     }, 1000); // 500ms delay

//     return () => clearTimeout(timer);
//   }, [location]);

//   return (
//     <>
//       {loading && <Loader />}
//       <Top />
//       <ScrollUp />
//       <Header />
//       <Routes>
//         <Route path="/" element={<Home />} />
//         <Route path="/all-issues" element={<AllIssues />} />
//         <Route path="/all-issues-details/:id" element={<AllIssuesDetails />} />
//         <Route path="/journal-overview" element={<JournalOverview />} />
//         <Route path="/editorial-policy" element={<EditorialPolicy />} />
//         <Route
//           path="/submission-guidelines"
//           element={<SubmissionGuidelines />}
//         />
//         <Route path="/contact" element={<Contact />} />
//         <Route path="/login" element={<Login />} />
//         <Route path="/sign-up" element={<Registration />} />
//         <Route path="*" element={<NotFound />} />
//       </Routes>
//       <Footer />
//     </>
//   );
// }

// function App() {
//   return (
//     <div className="App">
//       <BrowserRouter>
//         <div id="main">
//           <AppContent />
//         </div>
//       </BrowserRouter>
//     </div>
//   );
// }

// export default App;

import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useEffect, useState } from "react";
import Header from "./components/Header/Header";
import ScrollUp from "./components/ScrollUp";
import Top from "./components/Top";
import NotFound from "./components/NotFound";
import Loader from "./components/Loader";
import Footer from "./components/Footer/Footer";
// import ProgressBar from "./components/ProgressBar";

import Home from "./pages/Home/Home";
import AllIssues from "./pages/AllIssues/AllIssues";
import AllIssuesDetails from "./pages/AllIssuesDetails/AllIssuesDetails";
import JournalOverview from "./pages/JournalOverview/JournalOverview";
import EditorialPolicy from "./pages/EditorialPolicy/EditorialPolicy";
import SubmissionGuidelines from "./pages/SubmissionGuidelines/SubmissionGuidelines";
import Contact from "./pages/Contact/Contact";
import Login from "./pages/LoginRegistration/Login";
import Registration from "./pages/LoginRegistration/Registration";

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false); // Set loading to false after 2 seconds
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="App">
      {isLoading ? (
        <Loader />
      ) : (
        <BrowserRouter>
          <div id="main">
            {/* <ProgressBar /> */}
            <Top />
            <ScrollUp />
            <Header />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/all-issues" element={<AllIssues />} />
              <Route
                path="/all-issues-details/:id"
                element={<AllIssuesDetails />}
              />
              <Route path="/journal-overview" element={<JournalOverview />} />
              <Route path="/editorial-policy" element={<EditorialPolicy />} />
              <Route
                path="/submission-guidelines"
                element={<SubmissionGuidelines />}
              />
              <Route path="/contact" element={<Contact />} />
              <Route path="/login" element={<Login />} />
              <Route path="/sign-up" element={<Registration />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
            <Footer />
          </div>
        </BrowserRouter>
      )}
    </div>
  );
}

export default App;
