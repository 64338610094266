// import React, { useEffect, useState } from "react";
// import { Link, useLocation } from "react-router-dom";

// const Header = () => {
//   const [menuOpen, setMenuOpen] = useState(false);
//   // const [isDropdownOpen, setIsDropdownOpen] = useState(false);
//   const [prevWidth, setPrevWidth] = useState(window.innerWidth); // Store previous width
//   const location = useLocation();

//   useEffect(() => {
//     const handleResize = () => {
//       const currentWidth = window.innerWidth;

//       // Reload only when crossing the 1065px threshold
//       if (
//         (prevWidth < 1065 && currentWidth >= 1065) ||
//         (prevWidth >= 1065 && currentWidth < 1065)
//       ) {
//         window.location.reload();
//       }

//       setPrevWidth(currentWidth); // Update previous width
//     };

//     window.addEventListener("resize", handleResize);

//     return () => window.removeEventListener("resize", handleResize);
//   }, [prevWidth]); // Depend on prevWidth to track changes

//   return (
//     <header className="main-header">
//       <div className="header-inner fl-wrap">
//         <div className="container">
//           <Link to="/" className="logo-holder">
//             <img src="/images/logo.png" alt="Logo" />
//           </Link>

//           {/* <div className="search_btn htact show_search-btn">
//             <i className="far fa-search"></i>
//             <span className="header-tooltip">Search</span>
//           </div> */}

//           <div className="srf_btn htact show-reg-form">
//             <i className="fal fa-user"></i>
//             <Link to="/login" className="text-white">
//               <span className="header-tooltip">Sign In</span>
//             </Link>
//           </div>

//           {/* Navigation Toggle for Mobile */}
//           <div
//             className="nav-button-wrap"
//             onClick={() => setMenuOpen(!menuOpen)}
//           >
//             <div className="nav-button">
//               <span></span>
//               <span></span>
//               <span></span>
//             </div>
//           </div>

//           {/* Navigation Menu */}
//           <div
//             className={`main-menu ${menuOpen ? "vismobmenu" : "nav-holder"}`}
//           >
//             <nav className={`${menuOpen ? "menusb sliding-menu" : ""}`}>
//               <ul>
//                 <li>
//                   <Link
//                     to="/"
//                     className={location.pathname === "/" ? "act-link" : ""}
//                     onClick={() => setMenuOpen(false)}
//                   >
//                     Home
//                   </Link>
//                 </li>

//                 <li>
//                   <Link
//                     to="/all-issues"
//                     className={
//                       location.pathname === "/all-issues" ? "act-link" : ""
//                     }
//                     onClick={() => setMenuOpen(false)}
//                   >
//                     All Issues
//                   </Link>
//                 </li>

//                 {/* <li>
//                   <Link
//                     to="#"
//                     className={location.pathname === "/about" ? "act-link" : ""}
//                     onClick={() => setIsDropdownOpen(!isDropdownOpen)}
//                   >
//                     About <i className="fas fa-caret-down" />
//                   </Link>
//                   {isDropdownOpen && (
//                     <ul>
//                       <li>
//                         <Link to="/about">About Us</Link>
//                       </li>
//                       <li>
//                         <Link to="/404">404</Link>
//                       </li>
//                     </ul>
//                   )}
//                 </li> */}

//                 <li>
//                   <Link
//                     to="/journal-overview"
//                     className={
//                       location.pathname === "/journal-overview"
//                         ? "act-link"
//                         : ""
//                     }
//                     onClick={() => setMenuOpen(false)}
//                   >
//                     Journal Overview
//                   </Link>
//                 </li>

//                 <li>
//                   <Link
//                     to="/editorial-policy"
//                     className={
//                       location.pathname === "/editorial-policy"
//                         ? "act-link"
//                         : ""
//                     }
//                     onClick={() => setMenuOpen(false)}
//                   >
//                     Editorial Policy
//                   </Link>
//                 </li>

//                 <li>
//                   <Link
//                     to="/submission-guidelines"
//                     className={
//                       location.pathname === "/submission-guidelines"
//                         ? "act-link"
//                         : ""
//                     }
//                     onClick={() => setMenuOpen(false)}
//                   >
//                     Submission Guidelines
//                   </Link>
//                 </li>

//                 {/* <li>
//                   <Link
//                     to="/author"
//                     className={
//                       location.pathname === "/author" ? "act-link" : ""
//                     }
//                   >
//                     Author
//                   </Link>
//                 </li> */}

//                 <li>
//                   <Link
//                     to="/contact"
//                     className={
//                       location.pathname === "/contact" ? "act-link" : ""
//                     }
//                     onClick={() => setMenuOpen(false)}
//                   >
//                     Contact
//                   </Link>
//                 </li>
//               </ul>
//             </nav>
//           </div>
//         </div>
//       </div>
//     </header>
//   );
// };

// export default Header;

// import React, { useEffect, useState } from "react";
// import { Link, useLocation } from "react-router-dom";
// import "./header.css";

// const Header = () => {
//   const [menuOpen, setMenuOpen] = useState(false);
//   const [prevWidth, setPrevWidth] = useState(window.innerWidth);
//   const location = useLocation();

//   const [logo, setLogo] = useState("/images/sajlp-logo-1111.png");

//   useEffect(() => {
//     const updateLogo = () => {
//       if (window.innerWidth <= 425) {
//         setLogo("/images/sajlp-logo(240x55).png"); // Image for 425px and below
//       } else {
//         setLogo("/images/sajlp-logo-1111.png"); // Image for above 425px
//       }
//     };

//     updateLogo(); // Initial check
//     window.addEventListener("resize", updateLogo);

//     return () => window.removeEventListener("resize", updateLogo);
//   }, []);

//   useEffect(() => {
//     let timeout;

//     const handleResize = () => {
//       clearTimeout(timeout);
//       timeout = setTimeout(() => {
//         const currentWidth = window.innerWidth;

//         // Check if it's the first visit for this screen size
//         const storedWidth = sessionStorage.getItem("screenWidth");

//         if (
//           !storedWidth || // First visit, force reload
//           (prevWidth < 1065 && currentWidth >= 1065) ||
//           (prevWidth >= 1065 && currentWidth < 1065) ||
//           (prevWidth < 1024 && currentWidth >= 1024) ||
//           (prevWidth >= 1024 && currentWidth < 1024) ||
//           (prevWidth < 768 && currentWidth >= 768) ||
//           (prevWidth >= 768 && currentWidth < 768) ||
//           (prevWidth < 425 && currentWidth >= 425) ||
//           (prevWidth >= 425 && currentWidth < 425) ||
//           (prevWidth < 375 && currentWidth >= 375) ||
//           (prevWidth >= 375 && currentWidth < 375) ||
//           (prevWidth < 320 && currentWidth >= 320) ||
//           (prevWidth >= 320 && currentWidth < 320)
//         ) {
//           sessionStorage.setItem("screenWidth", currentWidth); // Store the width
//           window.location.reload();
//         }

//         setPrevWidth(currentWidth);
//       }, 300); // Adjust debounce time as needed
//     };

//     // First-time load check
//     if (!sessionStorage.getItem("screenWidth")) {
//       sessionStorage.setItem("screenWidth", window.innerWidth);
//       window.location.reload();
//     }

//     window.addEventListener("resize", handleResize);

//     return () => {
//       clearTimeout(timeout);
//       window.removeEventListener("resize", handleResize);
//     };
//   }, [prevWidth]);

//   return (
//     <header className="main-header">
//       <div className="header-inner fl-wrap">
//         <div className="container">
//           <Link to="/" className="logo-holder">
//             <img src={logo} alt="Logo" style={{ height: "42px" }} />
//           </Link>

//           <div className="srf_btn htact show-reg-form d-none">
//             <i className="fal fa-user"></i>
//             <Link to="/login" className="text-white">
//               <span className="header-tooltip">Sign In</span>
//             </Link>
//           </div>

//           {/* Navigation Toggle for Mobile */}
//           <div
//             className="nav-button-wrap"
//             onClick={() => setMenuOpen(!menuOpen)}
//           >
//             <div className="nav-button">
//               <span></span>
//               <span></span>
//               <span></span>
//             </div>
//           </div>

//           {/* Navigation Menu */}
//           <div
//             className={`main-menu ${menuOpen ? "vismobmenu" : "nav-holder"}`}
//           >
//             <nav className={`${menuOpen ? "menusb sliding-menu" : ""}`}>
//               <ul>
//                 <li>
//                   <Link
//                     to="/"
//                     className={location.pathname === "/" ? "act-link" : ""}
//                     onClick={() => setMenuOpen(false)}
//                   >
//                     Home
//                   </Link>
//                 </li>

//                 <li>
//                   <Link
//                     to="/all-issues"
//                     className={
//                       location.pathname === "/all-issues" ? "act-link" : ""
//                     }
//                     onClick={() => setMenuOpen(false)}
//                   >
//                     All Issues
//                   </Link>
//                 </li>

//                 <li>
//                   <Link
//                     to="/journal-overview"
//                     className={
//                       location.pathname === "/journal-overview"
//                         ? "act-link"
//                         : ""
//                     }
//                     onClick={() => setMenuOpen(false)}
//                   >
//                     Journal Overview
//                   </Link>
//                 </li>

//                 <li>
//                   <Link
//                     to="/editorial-policy"
//                     className={
//                       location.pathname === "/editorial-policy"
//                         ? "act-link"
//                         : ""
//                     }
//                     onClick={() => setMenuOpen(false)}
//                   >
//                     Editorial Policy
//                   </Link>
//                 </li>

//                 <li>
//                   <Link
//                     to="/submission-guidelines"
//                     className={
//                       location.pathname === "/submission-guidelines"
//                         ? "act-link"
//                         : ""
//                     }
//                     onClick={() => setMenuOpen(false)}
//                   >
//                     Submission Guidelines
//                   </Link>
//                 </li>

//                 <li>
//                   <Link
//                     to="/contact"
//                     className={
//                       location.pathname === "/contact" ? "act-link" : ""
//                     }
//                     onClick={() => setMenuOpen(false)}
//                   >
//                     Contact
//                   </Link>
//                 </li>
//               </ul>
//             </nav>
//           </div>
//         </div>
//       </div>
//     </header>
//   );
// };

// export default Header;

import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "./header.css";

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [prevWidth, setPrevWidth] = useState(window.innerWidth);
  const location = useLocation();

  const [logo, setLogo] = useState("/images/sajlp-logo-1111.png");

  useEffect(() => {
    const updateLogo = () => {
      if (window.innerWidth <= 425) {
        setLogo("/images/sajlp-logo(240x55).png"); // Image for 425px and below
      } else {
        setLogo("/images/sajlp-logo-1111.png"); // Image for above 425px
      }
    };

    updateLogo(); // Initial check
    window.addEventListener("resize", updateLogo);

    return () => window.removeEventListener("resize", updateLogo);
  }, []);

  useEffect(() => {
    let timeout;
    const handleResize = () => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        const currentWidth = window.innerWidth;

        // Reload only when crossing significant breakpoints
        if (
          (prevWidth < 1065 && currentWidth >= 1065) ||
          (prevWidth >= 1065 && currentWidth < 1065) ||
          (prevWidth < 1024 && currentWidth >= 1024) ||
          (prevWidth >= 1024 && currentWidth < 1024) ||
          (prevWidth < 768 && currentWidth >= 768) ||
          (prevWidth >= 768 && currentWidth < 768) ||
          (prevWidth < 425 && currentWidth >= 425) ||
          (prevWidth >= 425 && currentWidth < 425) ||
          (prevWidth < 375 && currentWidth >= 375) ||
          (prevWidth >= 375 && currentWidth < 375) ||
          (prevWidth < 320 && currentWidth >= 320) ||
          (prevWidth >= 320 && currentWidth < 320)
        ) {
          window.location.reload();
        }

        setPrevWidth(currentWidth);
      }, 300); // Adjust debounce time as needed
    };

    window.addEventListener("resize", handleResize);

    return () => {
      clearTimeout(timeout);
      window.removeEventListener("resize", handleResize);
    };
  }, [prevWidth]);

  return (
    <header className="main-header">
      <div className="header-inner fl-wrap">
        <div className="container">
          <Link to="/" className="logo-holder">
            <img src={logo} alt="Logo" style={{ height: "42px" }} />
          </Link>

          <div className="srf_btn htact show-reg-form d-none">
            <i className="fal fa-user"></i>
            <Link to="/login" className="text-white">
              <span className="header-tooltip">Sign In</span>
            </Link>
          </div>

          {/* Navigation Toggle for Mobile */}
          <div
            className="nav-button-wrap"
            onClick={() => setMenuOpen(!menuOpen)}
          >
            <div className="nav-button">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </div>

          {/* Navigation Menu */}
          <div
            className={`main-menu ${menuOpen ? "vismobmenu" : "nav-holder"}`}
          >
            {menuOpen ? (
              <nav className="menusb sliding-menu">
                <div className="sliding-menu-wrapper">
                  <ul className="menu-panel-root">
                    <li>
                      <Link
                        to="/"
                        className={location.pathname === "/" ? "act-link" : ""}
                        onClick={() => setMenuOpen(false)}
                      >
                        Home
                      </Link>
                    </li>

                    <li>
                      <Link
                        to="/all-issues"
                        className={
                          location.pathname === "/all-issues" ? "act-link" : ""
                        }
                        onClick={() => setMenuOpen(false)}
                      >
                        All Issues
                      </Link>
                    </li>

                    <li>
                      <Link
                        to="/journal-overview"
                        className={
                          location.pathname === "/journal-overview"
                            ? "act-link"
                            : ""
                        }
                        onClick={() => setMenuOpen(false)}
                      >
                        Journal Overview
                      </Link>
                    </li>

                    <li>
                      <Link
                        to="/editorial-policy"
                        className={
                          location.pathname === "/editorial-policy"
                            ? "act-link"
                            : ""
                        }
                        onClick={() => setMenuOpen(false)}
                      >
                        Editorial Policy
                      </Link>
                    </li>

                    <li>
                      <Link
                        to="/submission-guidelines"
                        className={
                          location.pathname === "/submission-guidelines"
                            ? "act-link"
                            : ""
                        }
                        onClick={() => setMenuOpen(false)}
                      >
                        Submission Guidelines
                      </Link>
                    </li>

                    <li>
                      <Link
                        to="/contact"
                        className={
                          location.pathname === "/contact" ? "act-link" : ""
                        }
                        onClick={() => setMenuOpen(false)}
                      >
                        Contact
                      </Link>
                    </li>
                  </ul>
                </div>
              </nav>
            ) : (
              <nav>
                <ul>
                  <li>
                    <Link
                      to="/"
                      className={location.pathname === "/" ? "act-link" : ""}
                      onClick={() => setMenuOpen(false)}
                    >
                      Home
                    </Link>
                  </li>

                  <li>
                    <Link
                      to="/all-issues"
                      className={
                        location.pathname === "/all-issues" ? "act-link" : ""
                      }
                      onClick={() => setMenuOpen(false)}
                    >
                      All Issues
                    </Link>
                  </li>

                  <li>
                    <Link
                      to="/journal-overview"
                      className={
                        location.pathname === "/journal-overview"
                          ? "act-link"
                          : ""
                      }
                      onClick={() => setMenuOpen(false)}
                    >
                      Journal Overview
                    </Link>
                  </li>

                  <li>
                    <Link
                      to="/editorial-policy"
                      className={
                        location.pathname === "/editorial-policy"
                          ? "act-link"
                          : ""
                      }
                      onClick={() => setMenuOpen(false)}
                    >
                      Editorial Policy
                    </Link>
                  </li>

                  <li>
                    <Link
                      to="/submission-guidelines"
                      className={
                        location.pathname === "/submission-guidelines"
                          ? "act-link"
                          : ""
                      }
                      onClick={() => setMenuOpen(false)}
                    >
                      Submission Guidelines
                    </Link>
                  </li>

                  <li>
                    <Link
                      to="/contact"
                      className={
                        location.pathname === "/contact" ? "act-link" : ""
                      }
                      onClick={() => setMenuOpen(false)}
                    >
                      Contact
                    </Link>
                  </li>
                </ul>
              </nav>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;

// import React, { useEffect, useState } from "react";
// import { Link, useLocation } from "react-router-dom";

// const Header = () => {
//   const [menuOpen, setMenuOpen] = useState(false);
//   const location = useLocation();
//   const [logo, setLogo] = useState("/images/sajlp-logo-1111.png");

//   const toggleMenu = () => {
//     setMenuOpen(!menuOpen);
//   };

//   const closeMenu = () => {
//     setMenuOpen(false);
//   };

//   useEffect(() => {
//     const updateLogo = () => {
//       if (window.innerWidth <= 425) {
//         setLogo("/images/sajlp-logo(240x55).png"); // Image for 425px and below
//       } else {
//         setLogo("/images/sajlp-logo-1111.png"); // Image for above 425px
//       }
//     };

//     updateLogo(); // Initial check
//     window.addEventListener("resize", updateLogo);

//     return () => window.removeEventListener("resize", updateLogo);
//   }, []);

//   return (
//     <header className="main-header">
//       <div className="header-inner fl-wrap">
//         <div className="container">
//           {/* Logo */}
//           <Link to="/" className="logo-holder">
//           <img src={logo} alt="Logo" style={{ height: "42px" }} />
//           </Link>

//           {/* Sign In */}
//           <div className="srf_btn htact show-reg-form d-none">
//             <i className="fal fa-user"></i>
//             <Link to="/login" className="text-white">
//               <span className="header-tooltip">Sign In</span>
//             </Link>
//           </div>

//           {/* Hamburger Menu Toggle */}
//           <div className="nav-button-wrap" onClick={toggleMenu}>
//             <div className={`nav-button ${menuOpen ? "active" : ""}`}>
//               <span></span>
//               <span></span>
//               <span></span>
//             </div>
//           </div>

//           {/* Menu (Mobile/Responsive toggle) */}
//           <div
//             className={`main-menu ${menuOpen ? "vismobmenu" : "nav-holder"}`}
//           >
//             <nav>
//               <ul>
//                 <li>
//                   <Link
//                     to="/"
//                     className={location.pathname === "/" ? "act-link" : ""}
//                     onClick={closeMenu}
//                   >
//                     Home
//                   </Link>
//                 </li>
//                 <li>
//                   <Link
//                     to="/all-issues"
//                     className={
//                       location.pathname === "/all-issues" ? "act-link" : ""
//                     }
//                     onClick={closeMenu}
//                   >
//                     All Issues
//                   </Link>
//                 </li>
//                 <li>
//                   <Link
//                     to="/journal-overview"
//                     className={
//                       location.pathname === "/journal-overview"
//                         ? "act-link"
//                         : ""
//                     }
//                     onClick={closeMenu}
//                   >
//                     Journal Overview
//                   </Link>
//                 </li>
//                 <li>
//                   <Link
//                     to="/editorial-policy"
//                     className={
//                       location.pathname === "/editorial-policy"
//                         ? "act-link"
//                         : ""
//                     }
//                     onClick={closeMenu}
//                   >
//                     Editorial Policy
//                   </Link>
//                 </li>
//                 <li>
//                   <Link
//                     to="/submission-guidelines"
//                     className={
//                       location.pathname === "/submission-guidelines"
//                         ? "act-link"
//                         : ""
//                     }
//                     onClick={closeMenu}
//                   >
//                     Submission Guidelines
//                   </Link>
//                 </li>
//                 <li>
//                   <Link
//                     to="/contact"
//                     className={
//                       location.pathname === "/contact" ? "act-link" : ""
//                     }
//                     onClick={closeMenu}
//                   >
//                     Contact
//                   </Link>
//                 </li>
//               </ul>
//             </nav>
//           </div>
//         </div>
//       </div>
//     </header>
//   );
// };

// export default Header;
